.home-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}
.center{
  text-align: center;
  margin-top:25px;
  font-size: 4rem;
}

.photo-card {
  border: 1px solid #ccc;
  padding: 10px;
  flex-basis: 30%; /* Allow cards to take up 30% of the container width by default */
  max-width: 600px; /* Ensure they don't grow too large on large screens */
  min-width: 250px; /* Ensure a reasonable size on desktop before shrinking */
  height: auto;
  overflow: hidden;
}

.photo-card img {
  width: 100%; /* Image takes up the full width of the container */
  height: auto; /* Height is automatically adjusted to maintain aspect ratio */
  object-fit: contain; /* Ensure the entire image is shown within the container */
}

/* Mobile styling: make the photos larger on smaller screens */
@media (max-width: 768px) {
  .photo-card {
    width: 90%; /* Make the photo card larger, taking up more screen width */
    max-width: none; /* Remove max-width restriction on mobile */
    height: auto; /* Ensure the container height adjusts dynamically */
  }

  .photo-card img {
    width: 100%;
    height: auto;
    object-fit: contain; /* Ensure the whole image is visible */
  }
  .center {
    font-size:2rem;
  }
}

.pricing-container, .disc-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  margin-top:100px;
}
.disc-container{
  justify-content: space-evenly;
  
  
}
.disc-card{
  border: 1px solid #000;
  padding: 40px;
  width: 300px;  /* Increase the width for desktop */
  text-align: center;
  background-color: #7FDBFF;
  color:#000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}

.package{
  background-color: #FF8574;
  border: 1px solid black;
}

.single{
  background-color: #B6E3A1;
  border: 1px solid black;
}

.promo{
  background-color: aqua;
  border: 1px solid black;
}

.pricing-card {
  
  padding: 40px;
  width: 300px;  /* Increase the width for desktop */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}
.contact{
  color:#fff;
  margin-left:80px;
  margin-top:50px;
  font-size:2rem;

}



/* Mobile styling: stack pricing cards vertically on smaller screens */
@media (max-width: 768px) {
  .pricing-container {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 70%;  /* Make each card take up most of the screen on mobile */
    font-size: 16px;  /* Adjust font size for mobile */
  }
  .contact{
    font-size:1rem;
    margin-left:30px;
  }
  .disc-container, .pricing-container{
    margin-top:30px;
  }
  .iframe-container {
    padding-bottom: 75%; /* Adjust aspect ratio for mobile (4:3 or closer to square) */
  }
}

footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
  width: 100%;
}

footer p {
  margin: 0;
  font-size: 16px;
}

/* Mobile styling: adjust padding and font size on smaller screens */
@media (max-width: 768px) {
  footer {
    padding: 10px 0;
  }

  footer p {
    font-size: 14px;
  }
}

.about{
  color:#fff;
  font-size: 1.2rem;
  font-weight: 700;
}

.call{
  color: #FF7F50;
}

.hidden{
  
}
.aqua{
  color:aqua
}
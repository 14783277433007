/* Ensure the html and body take full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(112, 115, 119);
}

/* Main container for the app, use Flexbox to manage layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;  /* Full height of the viewport */
}

/* The content area will grow to fill the available space */
.content {
  flex: 1; /* This ensures that the content grows to push the footer down */
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  background-color: #333;
  color: white;
  position: relative;
}

h1 {
  margin: 0;
  font-size: 1.5rem;
  color: white;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size:2rem;
}

nav a:hover {
  color: #74e768;
}

/* Hide links on mobile screens by default */
.nav-links {
  display: flex;
}

@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide by default */
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    width: 100%;
    flex-direction: column;
    text-align: center;
  }

  .nav-links.show {
    display: flex; /* Show the links when the hamburger menu is open */
  }

  .nav-links li {
    margin: 15px 0;
  }

  /* Hamburger Icon */
  .menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 30px;
  }

  .bar {
    height: 4px;
    width: 100%;
    background-color: white;
    margin: 4px 0;
    transition: all 0.3s;
  }

  .menu-toggle.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}

/* Desktop Menu */
@media (min-width: 769px) {
  .menu-toggle {
    display: none; /* Hide hamburger on larger screens */
  }
}

